/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import ckeditor5 from "/node_modules/.strapi/vite/deps/@_sh_strapi-plugin-ckeditor_strapi-admin.js?v=5ac292b5";
import contentManager from "/node_modules/.strapi/vite/deps/@strapi_content-manager_strapi-admin.js?v=5ac292b5";
import contentReleases from "/node_modules/.strapi/vite/deps/@strapi_content-releases_strapi-admin.js?v=5ac292b5";
import i18N from "/node_modules/.strapi/vite/deps/@strapi_i18n_strapi-admin.js?v=5ac292b5";
import strapiCloud from "/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=5ac292b5";
import usersPermissions from "/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=5ac292b5";
import reviewWorkflows from "/node_modules/.strapi/vite/deps/@strapi_review-workflows_strapi-admin.js?v=5ac292b5";
import { renderAdmin } from "/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=5ac292b5";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    ckeditor5: ckeditor5,
    "content-manager": contentManager,
    "content-releases": contentReleases,
    i18n: i18N,
    "strapi-cloud": strapiCloud,
    "users-permissions": usersPermissions,
    "review-workflows": reviewWorkflows,
  },
});
